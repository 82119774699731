import React, { Fragment, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mail from "./comp/mail";
import NavBar from "./comp/Nav";
import  Work  from "./comp/work";
//contextProvider


const App = (props) => {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
    
      <Routes>
        <Route path="/" element={<Work />} />
        <Route path="/nav" element={<NavBar />} />
        <Route path="/mail" element={<Mail />} />
        
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={3001}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default App;
