import React, { useRef } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import NavBar from "./Nav";

const Work = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_evvu0zb",
        "template_5wn5g3v",
        form.current,
        "AFG7NifL5J5O1uo9g"
      )
      .then(
        () => {
          toast("Work Update Submit Successfull !", {
            type: "success",
          });
        },
        (error) => {
          toast.error(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
    < NavBar />
    <div className="text-center text-3xl py-8 text-gray-500">
        <h1> Send Your WorkUpdate</h1>
    </div>
      <div className="flex items-center justify-center ">
        <div className=" w-full px-12">
          <div>
            {/* forms */}

            <div className="md:grid md:grid-cols-1 my-8 md:gap-6">
              <div className="mt-5 md:col-span-2 md:mt-0 ">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className=" flex justify-center"
                >
                  <div className="  sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6  bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <input
                            required
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="given-name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="emp_id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            EMP ID
                          </label>
                          <input
                            required
                            type="text"
                            name="emp_id"
                            id="emp_id"
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="date"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Date
                                </label>
                                <input
                                  required
                                  type="date"
                                  name="date"
                                  id="date"
                                  autoComplete="date"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                      </div>
                      <div>
                        <label
                          htmlFor="work_update"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Work Update {" "}
                        </label>
                        <div className="mt-1">
                          <textarea
                            required
                            id="work_update"
                            name="work_update"
                            rows={5}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Your Today's WorkUpdate"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 text-center sm:px-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Work;
